//
//
//
//
//

export default {
  methods: {
    logout() {
      this.$store.dispatch("projectState/logout");
    },
  },
};
