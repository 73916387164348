export const state = () => ({
  darkMode: false,
  selectedLanguage: null,
  messages: [], //Error messages
  messageCounter: 0,
})

export const mutations = {
  setDarkMode(state, { isDarkMode })
  {
    state.darkMode = isDarkMode;
  },
  setLanguage(state, language)
  {
    state.selectedLanguage = language
  },
  addMessage(state, error)
  {
    state.messages.push(error);
    state.messageCounter++;
  },
  removeMessage(state, messageId)
  {
    state.messages = state.messages.filter((m) => m.id != messageId);
  },
  removeAllMessages(state)
  {
    state.messages = [];
  }
}

export const actions = {
  async displayMessage(context, errorDetails)
  {
    errorDetails.id = context.state.messageCounter;
    context.commit('addMessage', errorDetails);
    //TODO Sentry
  },
  dismissMessage(context, messageId)
  {
    context.commit('removeMessage', messageId);
  },
  dismissAllMessages(context)
  {
    context.commit('removeAllMessages')
  }
}
