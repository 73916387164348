import businessLayer from "~/plugins/businessLayer";

export const state = () => ({
  userName: null,
  userEmail: null,
  userAddress: {
    first_name: null,
    last_name: null,
    address_1: null,
    address_2: null,
    company: null,
    city: null,
    state: null,
    postcode: null,
    country: null,
    phone: null
  },

  myOrderedCount: 0,
  myPlacedCount: 0,
  orderedCount: 0,
  placedCount: 0,
  orders: {
    pagination: {
      total: 0
    },
    data: [
      /*
    FORMAT:
    orderId: int,
    cubeId: int,
    placed: boolean,
    timestamp: string.getTime()
    */
    ]
  },
  successfulInitialStateDownload: false
})

export const getters = {
  isLogged: state =>
  {
    return state.userName != null && state.userEmail != null;
  }
}

export const mutations = {
  setUserData(state, { name, email })
  {
    state.userName = name;
    state.userEmail = email;
  },
  editUser(state, newData)
  {
    var newAddress = state.userAddress;
    for(var property in newValues)
    {
      var addrProperty = newValues[property];
      if(addrProperty != null && addrProperty.trim() !== '')
      {
        newAddress[property] = addrProperty;
      }
    }
    state.userAddress = newAddress;
  },
  applyState(state, decodedResponse)
  {
    state.orderedCount = decodedResponse.orderedCount ?? state.orderedCount;
    state.placedCount = decodedResponse.placedCount ?? state.placedCount;
    if (decodedResponse.loggedIn)
    {
      state.myOrderedCount = decodedResponse.myOrderedCount ?? state.myOrderedCount;
      state.myPlacedCount = decodedResponse.myPlacedCount ?? state.myPlacedCount;
      state.userName = decodedResponse.name;
      state.userEmail = decodedResponse.email;
      state.userAddress = decodedResponse.address;

      if (decodedResponse?.orders?.data?.length)
      {
        state.orders = decodedResponse.orders;
      }
      else
      {
        state.orders = {
          pagination: {
            total: 0
          },
          data: [
          ]
        };
      }
    }
    else
    {
      state.userName = null;
      state.userEmail = null;
      state.myOrderedCount = 0;
      state.myPlacedCount = 0;
      state.orders = {
        pagination: {
          total: 0
        },
        data: [
        ]
      };

      state.userAddress = {
        first_name: null,
        last_name: null,
        address_1: null,
        address_2: null,
        company: null,
        city: null,
        state: null,
        postcode: null,
        country: null,
        phone: null
      };
    }

    if (decodedResponse.successfulInitialStateDownload) state.successfulInitialStateDownload = true;//We are optimistic ;)
  }
}

export const actions = {
  async downloadServerState(context, stateKind)
  {
    var decodedResponse = await businessLayer.getServerState(stateKind);
    if (!decodedResponse)
    {
      console.log('Undecodable response');
      return;
    }
    if (!decodedResponse.loggedIn && context.getters.isLogged)
    {
      console.log('Incosistent login state');//TODO Should report this incident to Sentry
    }
    context.dispatch('applyDataFromServer', decodedResponse);
  },
  async login(context, formData)
  {
    var decodedResponse = await businessLayer.login(formData);
    if (decodedResponse != false)
    {
      context.commit('setUserData', decodedResponse);
    }
    return decodedResponse;
  },
  async register(context, formData)
  {
    var decodedResponse = await businessLayer.register(formData);
    if (decodedResponse != false)
    {
      context.commit('setUserData', decodedResponse);
    }
    return decodedResponse;
  },
  async logout(context)
  {
    var logoutStatus = await businessLayer.logout();
    if (logoutStatus?.success == true)
    {
      context.commit('setUserData', { name: null, email: null });
    }
  },
  async editUser(context, newValues)
  {
    var decodedResponse = await businessLayer.editUser(newValues);
    if (decodedResponse != false && decodedResponse.success)
    {
      context.commit('editUser', newValues);
    }
    return decodedResponse;
  },
  convertServerTimestamps(context, orders)
  {
    for (var order of orders.data)
    {
      order.localTime = (new Date(parseInt(order.timestamp) * 1000))//UNIX works in seconds. JS in milliseconds
        .toLocaleString(this.$i18n.locale);//Timezone is applied automatically
    }
    return orders;
  },
  convertLocalTimestamps(context, orders)
  {
    for (var order of orders.data)
    {
      order.localTime = (new Date(parseInt(order.timestamp)))
        .toLocaleString(this.$i18n.locale);
    }
    return orders;
  },
  async applyDataFromServer(context, decodedResponse)
  {
    if (decodedResponse.loggedIn)
    {
      if (decodedResponse?.orders?.data?.length)
      {
        decodedResponse.orders = await this.dispatch('projectState/convertServerTimestamps', decodedResponse.orders);
      }
    }
    decodedResponse.successfulInitialStateDownload = true;//We are optimistic ;)

    context.commit('applyState', decodedResponse);
  },
  async fillTestData(context)
  {
    var state = {};
    state.myOrderedCount = 10;
    state.myPlacedCount = 5;
    state.orderedCount = 105000;
    state.placedCount = 100000;
    state.name = "Někdo Jiný";
    state.email = "o.s.dv.f@seznam.cz";
    state.loggedIn = true;
    function randomDate(start, end)
    {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      )
    }
    state.orders = await this.dispatch('projectState/convertLocalTimestamps', {
      pagination: {
        total: 10
      },
      data: [
        {
          orderId: 0,
          cubeId: 1000,
          placed: '◍',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 1,
          cubeId: 1001,
          placed: '◍',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 2,
          cubeId: 1002,
          placed: '◍',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 3,
          cubeId: 1003,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 4,
          cubeId: 1004,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 5,
          cubeId: 1005,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 6,
          cubeId: 1006,
          placed: '◍',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 7,
          cubeId: 1007,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 8,
          cubeId: 1008,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        },
        {
          orderId: 9,
          cubeId: 1009,
          placed: '',
          timestamp: randomDate(new Date(2012, 0, 1), new Date()).getTime(),
        }
      ]
    });
    context.commit('applyState', state);
  }
}
