import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=e86197f4&"
import script from "./LayoutHeader.vue?vue&type=script&lang=js&"
export * from "./LayoutHeader.vue?vue&type=script&lang=js&"
import style0 from "./LayoutHeader.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/runner/work/addcube/addcube/components/Logo.vue').default,QuickActions: require('/home/runner/work/addcube/addcube/components/QuickActions.vue').default,FloatingAddButton: require('/home/runner/work/addcube/addcube/components/FloatingAddButton.vue').default})
