//
//
//
//
//
//
//
//
//
//
//

import UiPluginsMixin from "~/uiPlugins/";
import i18nHead from '../uiPlugins/i18nHead';

export default {
  head() {
    return i18nHead(this);
  },
  mixins: [UiPluginsMixin],
};
