import { render, staticRenderFns } from "./error.vue?vue&type=template&id=66ac9f58&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavMenu: require('/home/runner/work/addcube/addcube/components/NavMenu.vue').default,ErrorPanel: require('/home/runner/work/addcube/addcube/components/ErrorPanel.vue').default})
