//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    login: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters["projectState/isLogged"];
    },
    userName() {
      return this.$store.state.projectState.userName;
    },
    accountNameOrLoginText() {
      if (this.loggedIn) {
        return this.userName;
      }
      return this.$t("pages.account");
    },
  },
};
