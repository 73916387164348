export default {
  lang_code: 'en',
  page_home: {
    we_build: 'We are building a world-unique and record-breaking creation',
    add_cube: 'add a cube',
  },
  page_own_quantinty: {
    you_build: 'Build a world-unique and record-breaking creation',
    how_many: 'How many cubes to buy?',
    invalid_quantity: 'Invalid quantity'
  },
  register: 'register',
  before_buy: ' before you buy?',
  benefits_of_registraion: 'benefits of registration',
  page_choose_cubes: {
    choose_cubes: 'Choose your cubes',
    cubes_description: ['Get one cube in The Way cubevel and a great Bonus',
      'Get two cubes in The Way cubevel and two great Bonuses',
      'Choose your own count of cubes for The Way cubevel and get a favorable price and three great Bonuses',]
  },
  page_checkout: { //TODO Will be on the wordpress side
    heading: 'Checkout',
    consent: 'I consent to the business terms and conditions and the privacy statement.',
    summary: 'Summary',
    pcs: 'CUBES',//TODO výběr měny
    use_paywall: 'Use a paywall',
    use_paypal: 'Use PayPal',
    total: 'Total'
  },
  page_thank_you: {
    title: 'Thank you',
    subtitle: 'Now we engrave the cubes with laser and add them to "The Way" and "The Friend" cubevels.<br>' +
      'Cubes will be added in the order of the accepted orders.<br>' +
      'We sent all the important information and further instructions to the email you entered.',
    additional: 'You\'ll find the placement of the cubes in your user account.'
  },
  page_login: {
    login: 'Log In',
    register: 'Register',
    password: 'Password',
    repeat: 'Repeat Password',
    password_doesnt_match: 'Passwords don\'t match!',
    benefit_1: 'Registered users can see the placement of their cubes in the cubevel.',
    benefit_2: 'Registered users can have a look at leaderboard with cubes of other user.',
    benefit_3: 'Vote on further use of the cubevel (whether it will be sold, transfered, rented, etc.) after the project ends.',
    submit_login: 'login',
    submit_register: 'register',
    remember: 'Remember me',
    lost: 'Lost your password?',
    alreadyLogged: 'You are logged in.'
  },
  page_why: {
    heading: 'What are we actually building',
    subheading: 'Become a co-owner of a unique cubevel "The Way"',
    astounding_size: 'Astounding size',
    astounding_size_lines: ['We build a cubevel - unique picture composed of wooden cubes.',
      'It will be 10 × 10 meters large',
      'Containing 144 000 cubes.'],
    unique_look: 'Unique look',
    unique_look_lines: ['In the picture there will be a statement "I am the way, the truth, and the life" in 33 languages!',
      'The cubes have the size of 27×27×27 mm.',
      'A part of a letter from the text "I am the way, the truth, and the life" or a letter from the text in background', //TODO informace o pozadí textu
    ],
    true_message: 'True message',
    true_message_lines: ['More than 2 billions of people all over the world have already made themself sure about the truthfulness of the message "I am the way, the truth, and the life"',
      'Each person has their inner deep desires and searches for something... Something such as the meaning of life. When they choose the right way, they really find it.',
      'However, which way? Which truth? What life? Who is the way, the truth and the life?'],
    more: 'find more...'
  },
  page_current_state: {
    title: 'Current State',
    what: 'What do we actually do?',
    caption_1: 'The final count of cubes in the cubevel "The Way" is 144000.',
    caption_2: '{count} cubes are currently ordered.',
    caption_3: '{count} cubes are physically in place...',
    caption_4: 'Overview of users and their cubes...',
    caption_5: 'Map of places from where users added their cubes...',
    caption_6: 'Placement of the cubes in the cubevel...',
    only_for_registered: 'Only for registered users',
    more: 'more information'
  },
  pages_placement: {
    title: 'Placement of cubes',
    your_cubes: 'Your cubes',
    ordered: 'You ordered',
    placed: 'We placed',
    all_ordered: 'All orders',
    total_stats: 'All cubes',
  },
  page_bonuses: {
    title: "Bonuses",
    bonuses: ["BONUS 1", "BONUS 2", "BONUS 3",],
    subheadings: ['The same amount of cubes you ordered for "The Way" cubevel will be added to a second cubevel "The Friend".', 'We\'ll put wishes and desires into each of the cubes in "The Friend" cubevel.',
      'You can gift some of your cubes.',],
    descriptions: ['The content of "The Friend" cubevel is secret. Cubes are added from the left side in one row and from the other side in every second row. This cubevel will be revealed by flipping the cubes once both the cubevels are filled.',
      'We will drill a hole into each cube before we add it into the cubevel. Then we\'ll insert a wish or a plea that you send us, printed and curled into a roll.',
      'Then the gifted person will own the cube in "The Way" cubevel and will be able to send their wish or a plea which we put into the cube in "The Friend" cubevel.'],
  },
  page_exact_cubes: {
    title: 'Details',
    wanna_different: "Do you want different quantity?<br>Click HERE.",
  },
  page_who: {
    title: 'About'
  },
  cubes: ['1 cube',
    '2 cubes',
    'custom quantity',
    '3 cubes or more'],
  add: 'add',
  buy: 'buy',
  min_max: 'min. 3 pcs - max. 33 pcs',
  discount: 'discount',
  enter_pieces: 'enter quantity',
  why_add: "why to add?",
  pages: {
    home: 'home',
    why: 'why to add a cube?',
    state: 'current state',
    checkout: 'checkout',
    support_us: 'support us',

    conditions: 'business conditions',
    privacy: 'privacy statement',
    who: 'who we are and why do we create that?',
    account: 'login / register'
  },
  page_account: {
    title: 'Account Page',
    orders: 'Orders',
    per_page: 'Items/page:',
    date: 'Date',
    count: 'Count',
    details: 'Account Details',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'E-mail',
    company: "Company",
    address_fields: "These fields are optional for you. You can use them to provide us contact information.",
    billing_address: 'Billing Address',
    address_2: 'Apt, Suite, Unit (Optional)',
    city: "City",
    state: "State",
    postcode: "Post Code",
    phone: "Phone Number",
    country: "Country",
    addressUndefined: 'Address Not Entered Yet',
    delete: 'Delete Account',
    sure_delete: 'Are you sure you want to delete your account permanently?',
    deleted_success: 'Your account was deleted',
    not_logged_in: 'Not logged in',
    log_first: 'You must log in to view this page.'
  },
  page_lost: {
    title: 'Password reset',
    reset: 'Reset'
  },
  card_in_nav: {
    the_way: 'The Way',
    build_with_us: 'Build a world-unique and record-breaking creation with us.'
  },
  map: 'Map of added cubes',
  loading: 'Loading...',
  loading_map: 'Loading cube map...',
  error: 'Error',
  error_occured: 'An error occured.',
  page_not_found: 'Page not found.',
  logout: 'Log Out',
  edit: 'Edit',
  save: 'Save',
  cancel: 'Cancel',
  errors: {
    dismissAll: 'Dismiss All',
    server: 'Server Response: ',
    decode: 'Error while decoding response from server',
    credentials: 'Cound not log in using specified credentials',
    auth_unknown: 'Unknown error while logging in',
    duplicate_user: 'User with this email already exists. <small>You can retrieve your password by clicking on "Lost your password".</small>',
    logout: 'Could not log out. Maybe you can solve the problem by <a href="https://support.google.com/accounts/answer/32050">cleaning your browser cookies</a>.',
    edit: 'Could not save new values'
  },
  orders_table: {
    order_id: 'Order #',
    cube_id: 'Cube #',
    localTime: 'When',
    placed: 'Placed',
  }
}
