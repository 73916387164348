import { render, staticRenderFns } from "./QuickActions.vue?vue&type=template&id=1e1d81b0&"
import script from "./QuickActions.vue?vue&type=script&lang=js&"
export * from "./QuickActions.vue?vue&type=script&lang=js&"
import style0 from "./QuickActions.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountLogoutButton: require('/home/runner/work/addcube/addcube/components/account/LogoutButton.vue').default,AccountPanel: require('/home/runner/work/addcube/addcube/components/account/Panel.vue').default,LangSwitcher: require('/home/runner/work/addcube/addcube/components/LangSwitcher.vue').default})
