//
//
//
//
//
//
//
//
//
//
//
//

import QuickActions from "./QuickActions.vue";
export default {
  props: {
    floatAdd: {
      type: Boolean,
      default: true,
    },
  },
  components: { QuickActions },
  computed: {
    drawerLink() {
      return {
        ...this.$route.query,
        returnTo: this.$route.path,
      };
    },
  },
};
