import BusinessLayer from "~/plugins/businessLayer";

const ignoredRoutes = ['showDrawer', 'thank-you'];

export default function (vueComponent)
{
  if (ignoredRoutes.findIndex((entry) => vueComponent.$route.path.indexOf(entry) != -1) != -1)
  {
    //current route is on the blacklist
    return;
  }

  //Instruct business layer to display errors as ErrorMessage components on the pages
  BusinessLayer.onError = (error, description = "", warning = false) =>
  {
    if(typeof(error) != 'string')
    {
      error = JSON.stringify(error)
    }
    vueComponent.$store.dispatch('appState/displayMessage', { error: vueComponent.$t(error), description, warning});
  };
}
